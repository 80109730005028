import React from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { flashActions, modalActions, userActions } from '../../actions';
import { axiosInstance } from '../../utils';
import ModalContainer from './ModalContainer';
import { UserSelectMultiValueLabel, UserSelectMultiValueRemove, UserSelectOption, UserSelectSingleValue } from '../Form/Fields/UserSelectComponents';

const TransferInvoiceAssignmentModal = ({ dispatch, id }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      current_approver: null,
      new_approver: null,
      note: null,
    },
  });

  const onSubmit = async data => {
    const params = {
      current_approver_id: data.current_approver?.id,
      new_approver_id: data.new_approver?.id,
      note: data.note,
    };

    await axiosInstance
      .patch(`v1/documents/${id}/attachment_approvals/transfer_assignment`, params)
      .then(() => {
        dispatch(flashActions.showSuccess('Assignment has been transferred'));
        Router.reload();
      })
      .catch(error => {
        dispatch(flashActions.showError(error));
      });
  };

  return (
    <ModalContainer
      title="Transfer Assignment"
      dismissible={!isSubmitting}
      reactModalProps={{
        className: 'c-modal__container',
      }}
    >
      <div className="c-modal__body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={isSubmitting}>
            <div className="form__group">
              <div className="form__control">
                <label htmlFor="current_approver">Current Approver</label>
              </div>
              <div className="form__control user-select">
                <Controller
                  name="current_approver"
                  control={control}
                  rules={{ required: 'Can not be blank' }}
                  render={({ field: { onChange, value, ref, ..._rest } }) => (
                    <AsyncSelect
                      inputRef={ref}
                      cacheOptions
                      defaultOptions
                      loadOptions={debounce((inputValue, callback) => userActions.getUserOptions(callback, { search: inputValue }), 500)}
                      classNamePrefix="react-select"
                      value={value}
                      onChange={v => onChange(v)}
                      blurInputOnSelect
                      components={{
                        Option: UserSelectOption,
                        MultiValueLabel: UserSelectMultiValueLabel,
                        SingleValue: UserSelectSingleValue,
                        MultiValueRemove: UserSelectMultiValueRemove,
                      }}
                      styles={{ menuPortal: base => ({ ...base }) }}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
                {errors.current_approver && <div className="invalid-feedback">{errors.current_approver.message}</div>}
              </div>
            </div>
            <div className="form__group">
              <div className="form__control">
                <label htmlFor="new_approver">New Approver</label>
              </div>
              <div className="form__control">
                <Controller
                  name="new_approver"
                  control={control}
                  rules={{ required: 'Can not be blank' }}
                  render={({ field: { onChange, value, ref, ..._rest } }) => (
                    <AsyncSelect
                      inputRef={ref}
                      cacheOptions
                      defaultOptions
                      loadOptions={debounce((inputValue, callback) => userActions.getUserOptions(callback, { search: inputValue }), 500)}
                      classNamePrefix="react-select"
                      value={value}
                      onChange={v => onChange(v)}
                      blurInputOnSelect
                      components={{
                        Option: UserSelectOption,
                        MultiValueLabel: UserSelectMultiValueLabel,
                        SingleValue: UserSelectSingleValue,
                        MultiValueRemove: UserSelectMultiValueRemove,
                      }}
                      styles={{ menuPortal: base => ({ ...base }) }}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
                {errors.new_approver && <div className="invalid-feedback">{errors.new_approver.message}</div>}
              </div>
            </div>
            <div className="form__group">
              <div className="form__control">
                <label htmlFor="note">Note</label>
              </div>
              <textarea
                className="form__control custom-height"
                {...register('note')}
                placeholder="Your note"
              />
            </div>
            <div className="footer" style={{ textAlign: 'right' }}>
              <button
                type="button"
                className="button button--link-dark"
                disabled={isSubmitting}
                onClick={() => dispatch(modalActions.hideModal())}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button button--primary"
                style={{ marginLeft: '10px', minWidth: '100px' }}
              >
                Transfer
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </ModalContainer>
  );
};

export default connect(state => state.modal)(TransferInvoiceAssignmentModal);
