import React, { useState } from 'react';
import { faExclamationCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import { flashActions, modalActions, userActions } from '../../actions';
import { axiosInstance, chunk } from '../../utils';
import { FileType } from '../FileType';
import ModalContainer from './ModalContainer';
import { UserSelectMultiValueLabel, UserSelectMultiValueRemove, UserSelectOption, UserSelectSingleValue } from '../Form/Fields/UserSelectComponents';

import './BulkApproveInvoice.scss';

const BulkTransferInvoiceAssignmentModal = ({ selectedItems, setReload, dispatch }) => {
  // const { register, handleSubmit } = useForm();
  const [failedItems, setFailedItems] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      current_approver: null,
      new_approver: null,
    },
  });

  const onSubmit = async data => {
    console.log(data);
    setSubmitting(true);

    let fails = [];

    const transferInvoiceAssignment = async item => {
      // update only if the current priority is different
      const params = {
        current_approver_id: data.current_approver?.id,
        new_approver_id: data.new_approver?.id,
      };
      await axiosInstance
        .patch(`/v1/documents/${item.id}/attachment_approvals/transfer_assignment`, params)
        .then(_res => {})
        .catch(err => {
          fails = [
            ...fails,
            {
              id: item.id,
              status: 'error',
              errorMessage: flashActions.errorMessage(err),
            },
          ];
        });
    };

    const batches = chunk(selectedItems); // [[Promises1, Promises2, ..., Promises15], [Promises16, ..., Promises30]]

    while (batches.length) {
      const batch = batches.shift();
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(batch.map(id => transferInvoiceAssignment(id)));
    }

    setFailedItems(fails);
    setSubmitClicked(true);
    if (fails.length === 0) {
      dispatch(flashActions.showSuccess(`All assignments have been transferred successfully`));
      dispatch(modalActions.hideModal());
    } else {
      setSubmitting(false);
      setAlertMessage('Some of the selected documents failed update. Please review them individually.');
      dispatch(flashActions.showError('Some of the selected documents failed update. Please review them individually.'));
    }
  };

  function handleAfterClose() {
    if (submitClicked) {
      setTimeout(() => setReload(true), 1200); // without the delay document API returns old data
    }
  }

  return (
    <ModalContainer
      title="BULK TRANSFER ASSIGNMENT"
      reactModalProps={{
        onAfterClose: handleAfterClose,
        className: 'c-modal__container c-modal__container--lg',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="c-modal__body bulk-approve-invoice-modal-body">
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <div className="form__group" style={{ width: '100%' }}>
              <div className="form__control">
                <label htmlFor="current_approver">Current Approver</label>
              </div>
              <div className="form__control">
                <Controller
                  name="current_approver"
                  control={control}
                  rules={{ required: 'Can not be blank' }}
                  render={({ field: { onChange, value, ref, ..._rest } }) => (
                    <AsyncSelect
                      inputRef={ref}
                      cacheOptions
                      defaultOptions
                      loadOptions={debounce((inputValue, callback) => userActions.getUserOptions(callback, { search: inputValue }), 500)}
                      classNamePrefix="react-select"
                      value={value}
                      onChange={v => onChange(v)}
                      blurInputOnSelect
                      components={{
                        Option: UserSelectOption,
                        MultiValueLabel: UserSelectMultiValueLabel,
                        SingleValue: UserSelectSingleValue,
                        MultiValueRemove: UserSelectMultiValueRemove,
                      }}
                      styles={{ menuPortal: base => ({ ...base }) }}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
                {errors.current_approver && <div className="invalid-feedback">{errors.current_approver.message}</div>}
              </div>
            </div>
            <div className="form__group" style={{ width: '100%' }}>
              <div className="form__control">
                <label htmlFor="new_approver">New Approver</label>
              </div>
              <div className="form__control">
                <Controller
                  name="new_approver"
                  control={control}
                  rules={{ required: 'Can not be blank' }}
                  render={({ field: { onChange, value, ref, ..._rest } }) => (
                    <AsyncSelect
                      inputRef={ref}
                      cacheOptions
                      defaultOptions
                      loadOptions={debounce((inputValue, callback) => userActions.getUserOptions(callback, { search: inputValue }), 500)}
                      classNamePrefix="react-select"
                      value={value}
                      onChange={v => onChange(v)}
                      blurInputOnSelect
                      components={{
                        Option: UserSelectOption,
                        MultiValueLabel: UserSelectMultiValueLabel,
                        SingleValue: UserSelectSingleValue,
                        MultiValueRemove: UserSelectMultiValueRemove,
                      }}
                      styles={{ menuPortal: base => ({ ...base }) }}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
                {errors.new_approver && <div className="invalid-feedback">{errors.new_approver.message}</div>}
              </div>
            </div>
          </div>
          <table className="table table--default" id="table-bulk-approve-invoice" style={{ minWidth: '50vw' }}>
            <thead>
              <tr>
                <th />
                <th>Plan Number</th>
                <th>Type</th>
                <th>Name</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedItems.map(item => {
                const failedItem = failedItems.find(e => e.id === item.id);

                return (
                  <React.Fragment key={item.id}>
                    {failedItem && (
                      <tr key={`${item.id}-error`} className="error">
                        <td colSpan={5}>
                          <FontAwesomeIcon icon={faExclamationCircle} size="sm" className="text--danger" />
                          &nbsp;&nbsp;
                          <span className="text--danger">{failedItem.errorMessage}</span>
                        </td>
                      </tr>
                    )}
                    <tr key={item.id}>
                      <td>
                        <FileType type={item?.file_extension} />
                      </td>
                      <td>{item?.sp_number?.replace(/^sp/i, '')}</td>
                      <td>{item?.category}</td>
                      <td>{item?.display_name}</td>
                      <td>
                        <a href={`/invoice?id=${item.id}`} target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" color="#4A90E2" />
                        </a>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>

          {alertMessage.length !== 0 && (
            <h4 className="text--danger" style={{ textAlign: 'center', marginTop: '10px' }}>
              {alertMessage}
            </h4>
          )}
        </div>

        <div className="c-modal__footer">
          <button
            type="button"
            className="button button--link-dark"
            disabled={submitting}
            onClick={() => dispatch(modalActions.hideModal())}
          >
            cancel
          </button>
          <button type="submit" className="button button--primary" disabled={submitting} style={{ marginLeft: '10px' }}>
            transfer
          </button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default connect(state => state.modal)(BulkTransferInvoiceAssignmentModal);
