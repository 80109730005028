import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { axiosInstance } from '../../../utils';
import { ActionForm } from './ActionForm';
import { flashActions } from '../../../actions';
import RecurringRuleTooltip from './RecurringRuleTooltip';
import { DropdownButton } from '../..';
import { getGLCodeTotalByType } from '../../../helpers';
import { invoiceConstants } from '../../../constants';

import './CancelHoldInvoice.scss';

const ApproveInvoiceForm = ({
  submitFailed,
  handleSubmit,
  closeModal,
  documentName,
  id,
  currentInvoice,
  currentUser,
  reloadInvoice,
  nextInvoice,
  nextInvoiceStrataPlan,
  setProcessing,
  processing,
  handleGoToNextInvoice,
  handleGoToFirstInvoice,
  handleGoToLastInvoice,
  canGoToFirstAndLastInvoices,
  isInvoiceOverrider,
  invoice_approval_v2,
}) => {
  const dispatch = useDispatch();
  const formState = useSelector(state => state.form.approveInvoice);
  const { values, syncErrors } = formState;
  const buildingProfileState = useSelector(state => state.buildingProfile);
  const adminGlCodes = useSelector(state => state.dms.adminGlCodes);
  const cwfGlCodes = useSelector(state => state.dms.cwfGlCodes);
  const {
    available_balance: availableCash,
    funds_reserve: reserveFund,
    admin_fund_balance: adminFund,
    sinking_fund_balance: sinkingFund,
  } = buildingProfileState.building;
  const invoiceAmount = parseFloat(currentInvoice?.invoice?.invoiced_price ?? 0);
  const recurringRules = currentInvoice.invoice?.applicable_recurring_rules || [];
  const invoiceLineItems = currentInvoice?.invoice?.invoice_line_items ?? [];

  const showInsufficientFundsWarning = useMemo(() => {
    if (!currentUser?.isTenantManager) return false;

    const availableBalance = parseFloat(availableCash ?? 0) - parseFloat(reserveFund ?? 0);
    return (invoiceAmount >= 0 && availableBalance < invoiceAmount) || availableBalance < 0;
  }, [availableCash, reserveFund, invoiceAmount]);

  const cwfCodeTotal = useMemo(() => (invoiceLineItems && cwfGlCodes ? getGLCodeTotalByType(invoiceLineItems, cwfGlCodes) : 0), [
    cwfGlCodes,
    invoiceLineItems,
  ]);

  const adminCodeTotal = useMemo(() => (invoiceLineItems && adminGlCodes ? getGLCodeTotalByType(invoiceLineItems, adminGlCodes) : 0), [
    adminGlCodes,
    invoiceLineItems,
  ]);

  const statusField = [
    {
      name: 'note',
      component: 'input',
      type: 'textarea',
      label: 'Please enter a note for approval:',
      placeholder: 'Write your note...',
    },
  ];

  const afterSubmit = (goToNext, isStrataPlan, isFirst, isLast) => {
    setProcessing(false);

    if (goToNext) {
      if (isFirst) {
        handleGoToFirstInvoice();
      } else if (isLast) {
        handleGoToLastInvoice();
      } else {
        handleGoToNextInvoice(isStrataPlan);
      }
    } else {
      reloadInvoice();
    }
  };

  const approveForPayment = async ({ goToNext, isStrataPlan, isFirst, isLast }) => {
    setProcessing(true);
    closeModal();

    const statusNote = values && values.note ? values.note : '';

    if (invoice_approval_v2) {
      await axiosInstance
        .patch(`v1/documents/${id}/attachment_approvals/approve`, { note: statusNote })
        .then(async () => afterSubmit(goToNext, isStrataPlan, isFirst, isLast))
        .catch(error => {
          dispatch(flashActions.showError(error));
          reloadInvoice();
        });
    } else {
      await axiosInstance
        .put(`v1/documents/${id}/invoice/approve`, { status_note: statusNote })
        .then(async () => afterSubmit(goToNext, isStrataPlan, isFirst, isLast))
        .catch(error => {
          dispatch(flashActions.showError(error));
          reloadInvoice();
        });
    }
  };

  const overrideApprove = async ({ goToNext, isStrataPlan }) => {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: 'Are you sure?',
      text: 'This invoice will not be sent to any Internal or External Approvers',
      confirmButtonText: 'OVERRIDE & APPROVE',
      showCancelButton: true,
      customClass: {
        title: 'swal2-title text--left',
        htmlContainer: 'swal2-html-container text--left',
        confirmButton: 'button button--danger',
        cancelButton: 'button button--secondary',
      },
    }).then(result => {
      if (result.isConfirmed) {
        closeModal();
        setProcessing(true);

        axiosInstance
          .put(`v1/documents/${id}/invoice/override`)
          .then(() => {
            dispatch(flashActions.showSuccess('You have approved for payment'));
            setProcessing(false);
            goToNext === true ? handleGoToNextInvoice(isStrataPlan) : reloadInvoice(true);
          })
          .catch(error => {
            dispatch(flashActions.showError(error));
            setProcessing(false);
          });
      }
    });
  };

  const approveAndGoToNext = () => {
    approveForPayment({ goToNext: true, isStrataPlan: false });
  };

  const approveAndGoToNextStrataPlanInvoice = () => {
    approveForPayment({ goToNext: true, isStrataPlan: true });
  };

  const approveAndGoToFirst = () => {
    approveForPayment({ goToNext: true, isFirst: true });
  };

  const approveAndGoToLast = () => {
    approveForPayment({ goToNext: true, isLast: true });
  };

  const isInvalidAdminTotal = adminCodeTotal > adminFund && adminCodeTotal !== 0;
  const isInvalidCwfTotal = cwfCodeTotal > sinkingFund && cwfCodeTotal !== 0;
  const showInvalidAdminTotal = isInvalidAdminTotal && !currentUser?.isStrataMember;
  const showInvalidCwfTotal = isInvalidCwfTotal && !currentUser?.isStrataMember;
  const showWarning = showInvalidAdminTotal || showInvalidCwfTotal || showInsufficientFundsWarning;

  return (
    <div className="cancel-hold-invoice-container">
      <h3 className="cancel-hold-invoice-title">Approve invoice?</h3>
      {currentUser?.isTenantManager && <RecurringRuleTooltip rules={recurringRules} />}

      {showWarning && (
        <div className="alert alert--warning">
          <strong>WARNING:</strong>
          <ul style={{ listStyleType: 'square' }}>
            {showInsufficientFundsWarning ? (
              <li style={{ listStyle: 'inside' }}>
                This invoice will be placed On Hold due to insufficient funds (includes any Reserve Funds in place for the Building)
              </li>
            ) : (
              <>
                {showInvalidAdminTotal && <li style={{ listStyle: 'inside' }}>{invoiceConstants.WARNING_MESSAGES.ADMIN_FUND_INSUFFICIENT}</li>}
                {showInvalidCwfTotal && <li style={{ listStyle: 'inside' }}>{invoiceConstants.WARNING_MESSAGES.CWF_FUND_INSUFFICIENT}</li>}
              </>
            )}
          </ul>
        </div>
      )}

      <p className="approve-warning-text">Approving this invoice will send it for payment.</p>
      <p className="cancel-hold-invoice-text">Are you sure you want approve {documentName}?</p>
      <ActionForm
        handleSubmit={handleSubmit}
        submitFailed={submitFailed}
        values={values}
        syncErrors={syncErrors}
        onSubmit={approveForPayment}
        field={statusField}
        closeModal={closeModal}
        buttonText="Approve"
        actionButtonColor="green"
      />
      {nextInvoice && (
        <div className="cancel-hold-invoice-buttons-container">
          <DropdownButton
            className="secondary approve-go-to-next-button"
            onClickHandler={approveAndGoToNext}
            disabled={processing}
            text="Approve & go to next Invoice"
          >
            <button disabled={!canGoToFirstAndLastInvoices} type="button" onClick={approveAndGoToFirst}>
              First Invoice in Tasks
            </button>
            <button disabled={!canGoToFirstAndLastInvoices} type="button" onClick={approveAndGoToLast}>
              Last Invoice in Tasks
            </button>
            <button disabled={!nextInvoiceStrataPlan || processing} type="button" onClick={approveAndGoToNextStrataPlanInvoice}>
              Same Plan Number
            </button>
          </DropdownButton>
        </div>
      )}

      {currentUser?.isTenantManager && (recurringRules.length > 0 || isInvoiceOverrider) && (
        <div className="cancel-hold-invoice-buttons-container">
          <button
            type="button"
            className="button override-approve-button"
            onClick={() => {
              overrideApprove({});
            }}
            disabled={processing}
          >
            Override & Approve
          </button>
        </div>
      )}
    </div>
  );
};

export const ApproveInvoice = reduxForm({
  form: 'approveInvoice',
  destroyOnUnmount: true,
  initialValues: {},
})(ApproveInvoiceForm);
