import React from 'react';
import { components } from 'react-select';
import { Avatar } from '../../Avatar';

import './UserSelectComponents.scss';

export const UserSelectMultiValueLabel = ({ children, ...props }) => {
  const { data } = props;
  const { fullName, full_name } = data;

  return (
    <components.MultiValueLabel {...props}>
      <div className="react-select__multi-value__label--user-label">
        <Avatar {...data} size="tiny" />
        {fullName || full_name}
      </div>
    </components.MultiValueLabel>
  );
};

export const UserSelectMultiValueRemove = ({ children, ...props }) => {
  const { data } = props;

  return <components.MultiValueRemove {...props}>{data?.isFixed ? <span /> : children}</components.MultiValueRemove>;
};

export const UserSelectOption = ({ data, ...props }) => {
  const { fullName, full_name, approved, inProcess } = data;

  return (
    <components.Option className={`${inProcess && approved && 'disabled'}`} {...props}>
      <div className="react-select__option--user-option">
        <Avatar {...data} size="tiny" />
        {fullName || full_name}
      </div>
    </components.Option>
  );
};

export const UserSelectSingleValue = ({ children, ...props }) => {
  const { data } = props;
  const { fullName, full_name } = data;

  return (
    <components.SingleValue {...props}>
      <div className="react-select__multi-value__label--user-label">
        <Avatar {...data} size="tiny" />
        {fullName || full_name}
      </div>
    </components.SingleValue>
  );
};
